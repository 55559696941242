.share-card-overlay {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.share-card-modal {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 550px;
  height: 380px;
  text-align: center;
}

.share-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  h5 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
  }

  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
}

.share-card {
  padding: 10px;
  border-radius: 5px;
  background: #ecedec;
  margin-bottom: 15px;
}

.share-card-body {
  .share-logo {
    width: 80px;
    margin-bottom: 10px;
  }

  .share-open-link {
    display: block;
    padding: 10px;
    background: #ecedec;
    text-decoration: none;
    border-radius: 5px;
    margin-bottom: 15px;
  }

  .share-title {
    font-weight: normal;
    margin-bottom: 10px;
    text-align: start;
    padding-left: 10px;
    padding-top: 10px;
  }

  .share-buttons {
    padding-top: 10px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 10px;
    padding-bottom: 20px;

    .share-btn {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      cursor: pointer;
      background-color: #dceff3;
    }
  }

  .share-copy {
    display: flex;
    align-items: center;
    background-color: #f8f9fc;
    border: 1px solid #d1d5db;
    border-radius: 8px;
    padding: 6px;
    max-width: none;
    gap: 8px;

    .share-url {
      flex-grow: 1;
      border: none;
      background: transparent;
      font-size: 14px;
      color: #334155;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &:focus {
        outline: none;
      }
    }

    .copy-button {
      background: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
      border-radius: 50%;
      transition: background 0.2s ease-in-out;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }

      .copy-icon {
        width: 18px;
        height: 18px;
        opacity: 0.7;
      }
    }
  }

  .copy-success {
    color: #16a34a;
    font-size: 12px;
    margin-top: 4px;
    font-weight: bold;
  }
}


