.modal__icon-modal {
  width: 50px;
}

.modal__link-button {
  width: 50%;
}

.text-error {
  color: red;
  font-size: 16px;
  margin-top: 5px;
}