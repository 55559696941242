.expanded-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: white;
	border-radius: 1rem;
	cursor: pointer;
	text-align: center;
	width: 100%;
	max-width: 180px;
	aspect-ratio: 1 / 1;
}

.icon-container {
	margin-bottom: 0.25rem;
	margin-top: 0.5rem;
	border-radius: 1rem;
}
	.img {
			height: 6rem; // 24px * 4
			width: 6rem;
			object-fit: contain;
			border-radius: 1rem;
		}

		.span {
			font-size: 0.875rem; // Texto alternativo si no hay imagen
			color: #6b7280; // Gris oscuro
		}

	.title {
		font-weight: normal;
		color: #116b9b; // Azul primario
	}
