.expandable-title {
  font-weight: bold;
  color: #116b9b;
  font-size: 16px;
  align-items: center;
  white-space: nowrap;
  flex: 1;


  &.disabled {
    color: #9e9e9e;
  }
}

.separator {
  margin: 0 5px;  /* Añadimos espacio alrededor del guion */
}

.expandable-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.expandable-number {
  display: none;
  font-weight: bold;
  color: #116b9b;
  font-size: 16px;
  align-items: center;
  white-space: nowrap;
  flex: 1;

  &.disabled {
    color: #9e9e9e;
  }
}

@media (max-width: 600px) {
  .hide-text-mobile {
    display: none;
  }

  .expandable-number {
    display: block;
  }
}
