.buttons-container {
  display: flex;
  align-items: center;
  gap: 12px; /* Espaciado entre botones */
  flex-wrap: nowrap; /* Evita que los iconos se muevan a una nueva línea */
  overflow: auto; /* Permite desplazamiento si hay muchos iconos */
  white-space: nowrap; /* Evita el salto de línea */
}

@media (max-width: 768px) {
  .buttons-container {
    display: none;
  }
}
